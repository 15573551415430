// @flow

import React from 'react'
import classnames from 'classnames'
import { TextField, Button } from '@morpheus-ui/core-gatsby'
import { Form, type FormSubmitPayload } from '@morpheus-ui/forms'
import CircleArrowRight from '@morpheus-ui/icons/CircleArrowRight'

import './styles.scss'

type State = {
  submitting: boolean,
  submitted: boolean,
  hasError: boolean,
}

export default class ContractForm extends React.Component<{}, State> {
  state = {
    submitting: false,
    submitted: false,
    hasError: false,
  }

  onSubmit = (payload: FormSubmitPayload) => {
    if (payload.valid) {
      this.setState({ submitting: true, hasError: false }, () => {
        const newInput = {
          ...payload.fields,
          datetime: new Date().toJSON(),
        }

        fetch(
          // 'http://localhost:5000/mainframe-team/us-central1/website/send',
          'https://us-central1-mainframe-team.cloudfunctions.net/website/send',
          {
            method: 'POST',

            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newInput),
          },
        )
          .then(() => {
            this.setState({
              submitted: true,
              submitting: false,
              hasError: false,
            })
          })
          .catch(() => {
            this.setState({
              submitted: true,
              submitting: false,
              hasError: true,
            })
          })
      })
    }
  }

  render() {
    const { hasError, submitting, submitted } = this.state

    return (
      <div className="contact-form">
        <div className="contact-form-inner">
          <h2>Send us a message!</h2>

          <p className={classnames('error-message', { 'has-error': hasError })}>
            Something went wrong! Please try again later.
          </p>

          {submitted ? (
            <p>Your message has been sent! Thank you for contacting us.</p>
          ) : (
            <Form onSubmit={this.onSubmit}>
              <TextField label="Name" name="name" required />
              <TextField label="Email" name="email" type="email" required />
              <TextField
                label="Message"
                name="message"
                required
                multiline
                numberOfLines={5}
              />
              <div className="submit">
                <Button
                  disabled={submitting}
                  Icon={CircleArrowRight}
                  submit
                  title="SUBMIT"
                  theme={BUTTON_THEME}
                />
              </div>
            </Form>
          )}
        </div>
      </div>
    )
  }
}

const BUTTON_THEME = {
  backgroundColor: 'transparent',
  backgroundHoverColor: 'transparent',
  iconBackgroundColor: 'transparent',
  iconHoverBackgroundColor: 'transparent',
  padding: 0,
  titleColor: '#DA1157',
  titleHoverColor: '#BA104B',
  iconColor: '#DA1157',
  iconHoverColor: '#BA104B',
  borderWidth: 0,
  iconPadding: '3px',
  letterSpacing: '1.1px',
  backgroundDisabledColor: 'transparent',
  titleDisabledColor: '#F8CFDD',
  iconWidth: 32,
  iconHeight: 32,
}
