// @flow

import React from 'react'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'

import './contact.scss'

const Contact = () => (
  <Layout useContainer={false}>
    <div className="contact-page">
      <div className="container">
        <h1 className="title">Contact us</h1>
        <ContactForm />
      </div>
    </div>
  </Layout>
)

export default Contact
